import { atom, useSetRecoilState } from 'recoil'

export const drawerState = atom({
  key: 'drawerState',
  default: {
    isOpen: false,
  },
})

export const useOpenDrawer = () => {
  const setState = useSetRecoilState(drawerState)
  const openDrawer = () => {
    setState({ isOpen: true })
  }
  return openDrawer
}
